import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { StyleSheetManager } from 'styled-components';

import { Box, Grid } from '@core';
import { Animation, Text } from '@components';
import { useTimerContext } from '@context';

const NumberColumn = ({ number }) => (
  <Grid.Col width={21} backgroundColor="timer.numberBackground" borderRadius={4}>
    <Text.Body2Strong
      textAlign="center"
      fontSize={20}
      lineHeight={1}
      color="timer.numberColor"
      my={6}
    >
      {number}
    </Text.Body2Strong>
  </Grid.Col>
);

const TimerItem = ({ value, label }) => {
  const labelText = label[0].toUpperCase() + label.slice(1);

  return (
    <Grid.Container>
      <Grid.Row
        width={48}
        justifyContent="space-between"
        backgroundColor="timer.borderColor"
        border="2px solid"
        borderColor="timer.borderColor"
        borderRadius="sm"
      >
        <NumberColumn number={value[0]} />
        <NumberColumn number={value[1]} />
      </Grid.Row>
      <Grid.Row justifyContent="center">
        <Text.Body4 color="timer.numberLabelColor" fontSize={10} lineHeight={1} mt={3}>
          {labelText}
        </Text.Body4>
      </Grid.Row>
    </Grid.Container>
  );
};

const TimerWrapper = styled.span`
  direction: ltr;
`;

const Separator = () => (
  <Text.Body2 as="span" lineHeight="34px" color="timer.numberBackground" mx={2}>
    :
  </Text.Body2>
);

const DealsTimer = () => {
  const { t } = useTranslation();
  const { hours, minutes, seconds, isClientSideReady } = useTimerContext();

  return (
    <Box minWidth={typeof days === 'undefined' ? 192 : 247} minHeight={76}>
      {isClientSideReady && (
        <Animation.FadeIn>
          <Box borderRadius={4} backgroundColor="timer.backgroundColor" pt={8} px={16} pb={3}>
            <Text.Body4
              color="timer.titleColor"
              textAlign="center"
              fontSize={10}
              lineHeight={1}
              mb={6}
            >
              {t('deals:timer.expiresIn')}
            </Text.Body4>
            <StyleSheetManager stylisPlugins={[]}>
              <TimerWrapper>
                <Box display="flex" alignItems="flex-start">
                  <TimerItem value={hours} label={t('deals:timer.hours')} />
                  <Separator />
                  <TimerItem value={minutes} label={t('deals:timer.minutes')} />
                  <Separator />
                  <TimerItem value={seconds} label={t('deals:timer.seconds')} />
                </Box>
              </TimerWrapper>
            </StyleSheetManager>
          </Box>
        </Animation.FadeIn>
      )}
    </Box>
  );
};

export default DealsTimer;
